import React, { useEffect, useState } from "react";
import Recorder from "js-audio-recorder";
import Chat, { Bubble, useMessages, Button, toast } from "@chatui/core";
import "@chatui/core/dist/index.css";
import aicontent from "./info/ai.txt";
import aicontent14 from "./info/ai14.txt";
import aicontent17 from "./info/ai17.txt";
import axios from "axios";
import { OpenAIStream } from "./https";
import "./App.css";

const CurrentDialog = {
  0: "您好！您可以问关于上山文化的任何问题，我会为您解答？",
  14: "您好！您可以问关于上山文化的任何问题，我会为您解答？",
  17: "您好！您可以问关于`泛舟山海间`的任何问题，我会为您解答？",
};

const CurrentContent = {
  0: aicontent,
  14: aicontent14,
  17: aicontent17,
};

export default function App() {
  const site_id = window.location.href.includes("=")
    ? window.location.href.split("=")[1]
    : 0;
  const { messages, appendMsg, updateMsg, setTyping } = useMessages([
    {
      type: "text",
      content: {
        text: CurrentDialog[site_id],
      },
    },
  ]);
  const [inputType, setInputType] = useState("text");
  const [show, setShow] = useState(true);
  const [note, setNote] = useState("");
  const [cancel, setCancel] = useState(false);
  const [showListening, setShowListening] = useState(false);
  const [aiInput, setAiInput] = useState("");
  const recorder = new Recorder({
    sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
    sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
    numChannels: 1, // 声道，支持 1 或 2， 默认是1
    // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
  });

  useEffect(() => {
    fetch(CurrentContent[site_id])
      .then((r) => r.text())
      .then((text) => {
        setAiInput(text);
      });
  }, [aiInput]);

  function getByClass(oParent, sClass) {
    if (document.getElementsByClassName) {
      return oParent.getElementsByClassName(sClass);
    } else {
      var aEle = oParent.getElementsByTagName("*");
      var arr = [];
      for (var i = 0; i < aEle.length; i++) {
        var tmp = aEle[i].className.split(" ");
        if (findInArr(tmp, sClass)) {
          arr.push(aEle[i]);
        }
      }
      return arr;
    }
  }

  function findInArr(arr, n) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === n) return true;
    }
    return false;
  }

  async function handleSend(type, val) {
    if (type === "text" && val.trim()) {
      appendMsg({
        type: "text",
        content: { text: val },
        position: "right",
      });

      setTyping(true);

      try {
        var response = await OpenAIStream(val, aiInput);
        // const reader = stream.getReader();

        if (response.status != 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        let result = "";
        appendMsg({
          type: "text",
          content: { text: " " },
        });
        var doc = document.getElementById("root");
        const p = reader.read().then(function processStream({ done, value }) {
          if (done) {
            // 流结束
            setTimeout(() => {
              var dialogs = getByClass(doc, "Bubble text");
              console.log(dialogs.length);
              if (dialogs.length % 2 == 1) {
                dialogs[dialogs.length - 1].innerHTML = "<p>" + result + "</p>";
              }
            }, 1000);
            return;
          }
          const chunk = new TextDecoder("utf-8").decode(value);
          if (chunk === "[DONE]") {
            var dialogs = getByClass(doc, "Bubble text");
            console.log(dialogs.length);
            return;
          }
          for (var item of chunk.split("\n\n")) {
            if (item.includes("data") && !item.includes("[DONE]")) {
              try {
                console.log(item);
                const json = JSON.parse(item.split("data:")[1]);
                const text = json.choices[0].delta.content;
                result += text;
              } catch (error) {
                console.log(error)
              }
            }
          }
          console.log(result);
          // 递归调用读取下一个chunk
          return reader.read().then(processStream);
        });
      } catch (error) {
        console.log(error);
        toast.fail("系统失败");
      }
    }
  }

  function renderMessageContent(msg) {
    const { content } = msg;
    return <Bubble content={content.text} />;
  }

  return (
    show && (
      <>
        {showListening && (
          <div
            style={{
              width: "100vw",
              height: "20vh",
              position: "absolute",
              top: "0px",
              backgroundColor: "#D8D8D8",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            {note}
            <Button
              color="primary"
              style={{
                position: "absolute",
                top: "10vh",
                right: "20px",
                zIndex: 9999,
              }}
              onClick={(e) => {
                setShowListening(false);
                handleSend("text", note);
                setNote("");
              }}
            >
              确定
            </Button>
          </div>
        )}
        <Chat
          // navbar={{ title: "AI解说" }}
          messages={messages}
          renderMessageContent={renderMessageContent}
          onSend={handleSend}
          text={note}
          inputType={inputType}
          onInputTypeChange={async (e) => {
            if (e == "voice") {
              recorder.start();
            } else {
              setShowListening(false);
            }
          }}
          recorder={{
            canRecord: true,
            onStart: function (e) {
              recorder.stop();
              recorder.start();
            },
            onCancel: function (e) {
              recorder.stop();
            },
            onEnd: function (e) {
              const formData = new FormData();
              var filename = `${new Date().getTime()}.wav`;
              recorder.stop();
              formData.append("file", recorder.getWAVBlob(), filename);
              axios({
                // url: `http://localhost:3002/v1/ai/recognize_voice`,
                url: `/v1/ai/recognize_voice`,
                method: "POST",
                data: formData,
                headers: {
                  "Content-type": "multipart/form-data",
                },
              }).then((res) => {
                if (res.status == 200) {
                  setNote(res["data"]["data"]);
                }
              });
              setShowListening(true);
            },
          }}
        />
      </>
    )
  );
}
