import {
  createParser,
  ParsedEvent,
  ReconnectInterval,
} from "eventsource-parser";

const BaseInfo = {
  moon: {
    url: "https://api.moonshot.cn/v1/chat/completions",
    key: "sk-xMTRCbNN5OpIDLuj6sZ2E0UGwKdN2tBULydlj34SIAAyW97p",
    model: "moonshot-v1-128k",
  },
  deepSk: {
    url: "https://api.deepseek.com/chat/completions",
    key: "sk-08e939ce7f454a74b1e9bb22486821a7",
    model: "deepseek-chat",
  },
  huoshan: {
    url: "https://app.shierjue.com/v1/ai/chat",
    key: "86699ac9-509a-45a0-b4a9-d8d6edc9ee5d",
    model: "ep-20240603033034-8dfqq"
  }
};

const AiModel = "huoshan";

export const OpenAIStream = async (prompt, info) => {
  const encoder = new TextEncoder();
  const decoder = new TextDecoder();

  const res = await fetch(`${BaseInfo[AiModel]["url"]}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${BaseInfo[AiModel]["key"]}`,
    },
    mode: 'cors',
    dataType: "text/event-stream",
    method: "POST",
    body: JSON.stringify({
      model: BaseInfo[AiModel]["model"],
      messages: [
        {
          role: "system",
          content:
            "你是一个专业的博物馆策展人，最近策划了一个展览，目前关于这个展览的资料我已经全部上传。您对这个展览的内容非常熟悉，可以回答用户各种问题。当用户问你其他不关于博物馆的问题的时候，你也可以回答。如果有些关键词上传的资料里面有，网上的信息也有，请你先回答上传的资料库里的信息。如果问你是什么大模型的时候，你可以回答我是十二诀开发的自主大模型。",
        },
        { role: "system", content: info },
        { role: "user", content: prompt },
      ],
      temperature: 0.1,
      stream: true,
    }),
  });
  return res;

  //   if (res.status !== 200) {
  //     throw new Error("OpenAI API returned an error");
  //   }

  //   const stream = new ReadableStream({
  //     async start(controller) {
  //       const onParse = (event) => {
  //         if (event.type === "event") {
  //           const data = event.data;

  //           if (data === "[DONE]") {
  //             controller.close();
  //             return;
  //           }

  //           try {
  //             const json = JSON.parse(data);
  //             const text = json.choices[0].delta.content;
  //             const queue = encoder.encode(text);
  //             controller.enqueue(queue);
  //           } catch (e) {
  //             // controller.error(e);
  //             const queue = encoder.encode("系统错误");
  //             controller.enqueue(queue);
  //           }
  //         }
  //       };

  //       const parser = createParser(onParse);
  //       for await (const chunk of res.body) {
  //         parser.feed(decoder.decode(chunk));
  //       }
  //     },
  //   });
  //   return stream;
};
